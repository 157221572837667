import React from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { animated, useSpring, config } from "react-spring"

const SectionWrapper = styled.section`
  pointer-events: none;
  margin-top: -10rem;
  padding-top: 10px;
  width: 100%;
  position: relative;
  z-index: 12;
  mix-blend-mode: difference;
  @media (max-width: 879px) {
    margin-top: 0;
  }
`

const SloganWrapper = styled.div`
  text-align: right;
  position: relative;
  width: 100%;

  #ecomLogo {
    max-width: 50%;
  }
`

const HeroEcommerce = () => {
  const [ref, inView] = useInView({
    rootMargin: "100px 0px",
    triggerOnce: false,
  })

  const fadeIn = useSpring({
    config: config.slow,
    opacity: inView ? 1 : 0,
  })

  return (
    <SectionWrapper id="e-commerce">
      <SloganWrapper ref={ref} inView={inView}>
        <animated.svg
          style={fadeIn}
          id="ecomLogo"
          viewBox="0 0 431 410"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.5507 54.5702H55.1127V61.3486H11.5V1.5H53.8832V8.2853H21.5507H20.0507V9.7853V26.1241V27.6241H21.5507H50.0648V34.23H21.5507H20.0507V35.73V53.0702V54.5702H21.5507Z"
            stroke="white"
            strokeWidth="3"
          />
          <line
            x1="188"
            y1="31"
            x2="430"
            y2="31"
            stroke="white"
            strokeWidth="10"
          />
          <path
            id="pathline"
            fill="transparent"
            d="M189.167 27.7347H428.998V34.3198H189.167V27.7347Z"
            stroke="white"
            strokeWidth="3"
          />
          <path
            d="M38.0127 173.952H38.0122C19.7908 173.959 6.30176 161.028 6.30176 143.14C6.30176 125.255 19.7882 112.322 38.1015 112.322C47.3022 112.322 55.1792 115.474 60.6117 121.239L55.2832 126.3C50.6612 121.788 45.038 119.556 38.6274 119.556C24.9362 119.556 14.9276 129.371 14.9276 143.133C14.9276 156.902 24.9359 166.718 38.6274 166.718C45.0316 166.718 50.6621 164.479 55.2854 159.893L60.6184 165.028C55.1872 170.796 47.3094 173.952 38.0127 173.952Z"
            stroke="white"
            strokeWidth="3"
          />
          <path
            d="M185.815 143.402L185.814 143.411L185.815 143.419C185.862 149.91 188.508 156.115 193.17 160.68C197.831 165.245 204.129 167.801 210.687 167.796L210.695 167.796L210.703 167.796C215.546 167.738 220.265 166.269 224.267 163.571C228.27 160.873 231.379 157.066 233.2 152.625C235.021 148.184 235.473 143.31 234.497 138.615C233.522 133.921 231.163 129.618 227.72 126.247C224.278 122.876 219.906 120.586 215.154 119.663C210.402 118.739 205.479 119.222 201.003 121.052C196.527 122.882 192.696 125.978 189.992 129.954C187.289 133.931 185.834 138.609 185.815 143.402ZM243.5 143.408C243.5 160.813 229.226 174.5 210.5 174.5C191.774 174.5 177.5 160.813 177.5 143.408C177.5 126.014 191.762 112.5 210.5 112.5C229.238 112.5 243.5 126.014 243.5 143.408Z"
            stroke="white"
            strokeWidth="3"
          />
          <path
            d="M428.926 113.213L429.017 173.061H421.073L420.988 132.903L420.976 127.451L418.197 132.142L398.209 165.879H394.761L374.775 132.671L371.99 128.044V133.445V173.061H363.979V113.213H371.139L395.382 154.132L396.684 156.33L397.969 154.123L421.784 113.213H428.926Z"
            stroke="white"
            strokeWidth="3"
          />
          <path
            d="M66.4473 225.5L66.5384 285.349H58.5946L58.509 245.19L58.4974 239.738L55.7185 244.429L35.7305 278.167H32.2829L12.2963 244.959L9.51109 240.331V245.732V285.349H1.5V225.5H8.66041L32.904 266.42L34.2059 268.617L35.4908 266.41L59.306 225.5H66.4473Z"
            stroke="white"
            strokeWidth="3"
          />
          <path
            d="M199.211 278.065H232.779V284.843H189.167V225.002H231.543V231.787H199.211H197.711V233.287V249.626V251.126H199.211H227.725V257.732H199.211H197.711V259.232V276.565V278.065H199.211Z"
            stroke="white"
            strokeWidth="3"
          />
          <path
            d="M381.471 268.043V284.843H372.927V225.002H397.001C404.931 225.002 411.17 227.049 415.4 230.607C419.598 234.138 421.97 239.283 421.97 245.842C421.97 254.772 417.544 261.209 409.686 264.285L407.919 264.976L408.999 266.536L421.674 284.833L412.891 284.843L400.64 267.095L400.125 266.349L399.225 266.458C398.531 266.542 397.822 266.543 397.001 266.543H382.971H381.471V268.043ZM382.971 231.877H381.471V233.377V258.424V259.924H382.971H396.468C401.732 259.924 405.967 258.712 408.909 256.253C411.892 253.76 413.338 250.159 413.338 245.855C413.338 241.551 411.89 237.969 408.903 235.498C405.96 233.064 401.726 231.877 396.468 231.877H382.971Z"
            stroke="white"
            strokeWidth="3"
          />
          <path
            d="M38.0127 407.952H38.0122C19.7908 407.959 6.30176 395.028 6.30176 377.14C6.30176 359.255 19.7882 346.322 38.1015 346.322C47.3022 346.322 55.1792 349.474 60.6117 355.239L55.2832 360.3C50.6612 355.788 45.038 353.556 38.6274 353.556C24.9362 353.556 14.9276 363.371 14.9276 377.133C14.9276 390.902 24.9359 400.718 38.6274 400.718C45.0316 400.718 50.6621 398.479 55.2854 393.893L60.6184 399.028C55.1872 404.796 47.3094 407.952 38.0127 407.952Z"
            stroke="white"
            strokeWidth="3"
          />
          <path
            d="M199.217 401.069H232.779V407.848H189.167V347.999H231.55V354.784H199.217H197.717V356.284V372.623V374.123H199.217H227.732V380.729H199.217H197.717V382.229V399.569V401.069H199.217Z"
            stroke="white"
            strokeWidth="3"
          />
        </animated.svg>
      </SloganWrapper>
    </SectionWrapper>
  )
}

export default HeroEcommerce
