import React from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { animated, useSpring, config } from "react-spring"

const SectionWrapper = styled.section`
  pointer-events: none;
  margin-top: 20vh;
  width: 100%;
  position: relative;
  z-index: 12;
  mix-blend-mode: difference;
`

const SloganWrapper = styled.div`
  text-align: left;
  position: relative;
  width: 100%;

  #svg-hero {
    max-width: 50%;
    width: 100%;
  }
`

const Hero = ({ heroRef }) => {
  const [ref, inView] = useInView({
    rootMargin: "0px 0px",
    triggerOnce: true,
  })

  const fadeIn = useSpring({
    config: config.slow,
    opacity: inView ? 1 : 0,
  })

  return (
    <SectionWrapper ref={heroRef}>
      <SloganWrapper ref={ref} inView={inView}>
        <animated.svg
          style={fadeIn}
          id="svg-hero"
          viewBox="0 0 237 156"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27.1 3.70001L24.6 8.89999C20.8 6.59999 17 5.70001 14.3 5.70001C10.8 5.70001 8.60001 7.00002 8.60001 9.30002C8.60001 17 27.5 12.9 27.5 25.5C27.5 31.7 22 35.6 14.3 35.6C8.8 35.6 3.6 33.3 0 30L2.5 24.9C6.1 28.2 10.6 30 14.4 30C18.5 30 20.9 28.4 20.9 25.7C20.9 17.9 2 22.2 2 9.80002C2 3.80002 7.2 0 14.8 0C19.4 0.1 23.9 1.60001 27.1 3.70001Z"
            fill="white"
          />
          <path
            d="M133.2 17.9C133.2 27.9 125.1 35.7 114.6 35.7C104.1 35.7 96 27.9 96 17.9C96 7.79999 104.1 0.200012 114.6 0.200012C125.1 0.200012 133.2 7.89999 133.2 17.9ZM102.1 17.9C102.1 24.9 107.9 30.4 114.7 30.4C121.6 30.4 127.2 24.9 127.2 17.9C127.2 10.9 121.6 5.5 114.7 5.5C107.8 5.5 102.1 10.9 102.1 17.9Z"
            fill="white"
          />
          <path
            d="M31.9 65.9034L28.4 70.2034C25.8 67.4034 22.2 65.7034 18.6 65.7034C11.6 65.7034 6.10001 71.1034 6.10001 78.0034C6.10001 84.9034 11.6 90.4034 18.6 90.4034C22.1 90.4034 25.7 88.8034 28.4 86.3034L31.9 90.2034C28.3 93.7034 23.3 96.0034 18.3 96.0034C8 96.0034 0.100006 88.2034 0.100006 78.2034C0.100006 68.2034 8.2 60.6034 18.5 60.6034C23.5 60.5034 28.5 62.6034 31.9 65.9034Z"
            fill="white"
          />
          <path
            d="M133.2 77.9C133.2 87.9 125.1 95.7 114.6 95.7C104.1 95.7 96 87.9 96 77.9C96 67.8 104.1 60.2 114.6 60.2C125.1 60.2 133.2 67.9 133.2 77.9ZM102.1 77.9C102.1 84.9 107.9 90.4 114.7 90.4C121.6 90.4 127.2 84.9 127.2 77.9C127.2 70.9 121.6 65.5 114.7 65.5C107.8 65.5 102.1 70.9 102.1 77.9Z"
            fill="white"
          />
          <path d="M21.1 149.7V155H0V120H5.90001V149.7H21.1Z" fill="white" />
          <path
            d="M105.6 147.1L102.2 155H96L111.5 120H117.6L132.9 155H126.6L123.3 147.1H105.6ZM114.4 126.3L107.8 141.9H121L114.4 126.3Z"
            fill="white"
          />
          <path
            d="M223.3 120C230.6 120 235.1 123.3 235.1 128.8C235.1 132.8 232.8 135.7 228.9 136.6C233.6 137.4 236.4 140.8 236.4 145.5C236.4 151.5 231.5 155.1 223.6 155.1H208V120.1H223.3V120ZM214 134.5H223C226.8 134.5 229 132.7 229 129.8C229 126.9 226.7 125.2 223 125.2H214V134.5ZM214 149.7H223C227.6 149.7 230.4 147.9 230.4 144.6C230.4 141.5 227.6 139.6 223 139.6H214V149.7Z"
            fill="white"
          />
        </animated.svg>
      </SloganWrapper>
    </SectionWrapper>
  )
}

export default Hero
