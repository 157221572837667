import React, { useRef } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/animation/hero"
import ProjectsSectionTemplate from "../templates/projectsSectionTemplate"
import HeroEcommerce from "../components/animation/heroEcommerce"
import { useInView } from "react-intersection-observer"

const MainWrapper = styled.main`
  max-width: calc(100% - 80px - 20vw);
  width: 100%;
  margin: 0 auto;
  padding-bottom: 2rem;
  @media (max-width: 1240px) {
    max-width: calc(100% - 40px - 20vw);
  }
`

const IndexPage = ({ data, location }) => {
  const myRef = useRef(null)

  const [ref, inView] = useInView({
    rootMargin: "-200px 0px",
  })

  return (
    <Layout location={location} inView={inView}>
      <MainWrapper>
        <SEO
          title="Home"
          description="SOCOLAB - PHOTO | VIDEO | RETOUCH | PRODUCTION we are a creative agency based in Gdansk - Poland. LET’S TALK"
        />
        <Hero heroRef={ref} />
        <ProjectsSectionTemplate data={data.projects} />
        <HeroEcommerce myRef={myRef} />
        <ProjectsSectionTemplate data={data.ecommerce} />
      </MainWrapper>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
  query HomePageQuery {
    ecommerce: allMarkdownRemark(
      filter: { frontmatter: { title: { regex: "/Ecommerce/" } } }
      sort: { order: DESC, fields: frontmatter___title }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            projectTitle
            projectSubtitle
            coverImageObject {
              speed
              mgTop
              mgRight
              size
              align
              zIndex
              isScaled
              isGray
              coverVideo {
                setCoverVideo
                coverVideoSrcWebM
                coverVideoSrcMp4
              }
              mask {
                haveMask
                haveHalfMask
                maskColor
              }
              coverImage {
                id
                childImageSharp {
                  fluid(maxWidth: 860, quality: 70) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    projects: allMarkdownRemark(
      filter: { frontmatter: { title: { regex: "/Projekt/" } } }
      sort: { order: DESC, fields: frontmatter___title }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            projectTitle
            projectSubtitle
            coverImageObject {
              speed
              mgTop
              mgRight
              size
              align
              zIndex
              isScaled
              isGray
              coverVideo {
                setCoverVideo
                coverVideoSrcWebM
                coverVideoSrcMp4
              }
              mask {
                haveMask
                haveHalfMask
                maskColor
              }
              coverImage {
                id
                childImageSharp {
                  fluid(maxWidth: 860, quality: 70) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
