import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import useMousePosition from "@react-hook/mouse-position"
import { animated, useSpring } from "react-spring"
import { useScrollYPosition } from "react-use-scroll-position"
import { useInView } from "react-intersection-observer"

const ImageWrapper = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  margin-top: ${({ mgTop }) => (mgTop ? `${mgTop}%` : `-2%`)};
  justify-content: ${({ align }) =>
    (align === "lewa strona" && "flex-start") ||
    (align === "prawa strona" && "flex-end") ||
    (align === "środek" && "center")};
  @media (max-width: 879px) {
    justify-content: center;
    margin-top: 40px;
  }
`

const CoverVideo = styled.figure`
  position: relative;
  will-change: transform;
  overflow: hidden;
  width: 100%;
  max-width: ${({ size }) => `${size}%`};

  video {
    width: 100%;
    border: none;
  }
  @media (max-width: 879px) {
    max-width: 100%;
  }
`

const ProjektName = styled.h1`
  pointer-events: none;
  position: absolute;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  z-index: 100;
  color: #fff;
  font-size: 1.4vw;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 5px;
  overflow: hidden;
  background-color: transparent;
  mix-blend-mode: difference;
`

const ProjectVideo = ({
  title,
  link,
  speed,
  align,
  size,
  mgTop,
  zIndex,
  coverVideoSrcWebM,
  coverVideoSrcMp4,
}) => {
  const scrollY = useScrollYPosition()

  const [mousePositionRef, mousePosition] = useMousePosition(
    20, // enterDelay
    20, // leaveDelay
    30 // fps
  )

  const x = mousePosition.pageX
  const y = mousePosition.pageY
  const isOver = mousePosition.isOver

  const [ref, inView] = useInView({
    rootMargin: "200px 0px",
  })

  const { sY } = useSpring({
    config: {
      mass: 3,
      tension: 220,
      friction: 500,
      clamp: true,
    },
    sY: inView && window.innerWidth > 879 ? scrollY : "none",
  })

  const { o } = useSpring({
    o: inView ? 1 : 0,
  })

  return (
    <>
      <ImageWrapper ref={ref} align={align} mgTop={mgTop}>
        <AnimatedCoverVideo
          ref={mousePositionRef}
          size={size}
          style={{
            zIndex: zIndex,
            transform: sY.interpolate(
              (sY) => `translate3d(0, ${sY * speed}px, 0)`
            ),
            opacity: o.interpolate((o) => o),
          }}
        >
          <Link style={{ cursor: "pointer" }} to={link} aria-label={title}>
            <video autoPlay playsInline disablePictureInPicture muted loop>
              {coverVideoSrcWebM && (
                <source src={coverVideoSrcWebM} type="video/webm" />
              )}
              {coverVideoSrcMp4 && (
                <source src={coverVideoSrcMp4} type="video/mp4" />
              )}
            </video>
          </Link>
        </AnimatedCoverVideo>
        <ProjektName
          style={{
            top: isOver ? `${y + 5}px` : "none",
            left: isOver ? `${x + 20}px` : "none",
            opacity: isOver ? "1" : "0",
          }}
        >
          {title}
        </ProjektName>
      </ImageWrapper>
    </>
  )
}

const AnimatedCoverVideo = animated(CoverVideo)

export default ProjectVideo
