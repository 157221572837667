import React from "react"

import ProjectImage from "../components/projectImage"
import ProjectVideo from "../components/projectVideo"

const ProjectsSectionTemplate = ({ data }) => {
  // Helpers
  const { edges: projects } = data

  const projectCover = projects.map((project) => {
    return project.node.frontmatter.coverImageObject.coverVideo
      .setCoverVideo ? (
      <ProjectVideo
        key={project.node.id}
        title={`${project.node.frontmatter.projectTitle} ${project.node.frontmatter.projectSubtitle}`}
        coverVideoSrcMp4={
          project.node.frontmatter.coverImageObject.coverVideo.coverVideoSrcMp4
        }
        coverVideoSrcWebM={
          project.node.frontmatter.coverImageObject.coverVideo.coverVideoSrcWebM
        }
        link={`/${project.node.frontmatter.path}/`}
        speed={project.node.frontmatter.coverImageObject.speed}
        align={project.node.frontmatter.coverImageObject.align}
        size={project.node.frontmatter.coverImageObject.size}
        mgTop={project.node.frontmatter.coverImageObject.mgTop}
        zIndex={project.node.frontmatter.coverImageObject.zIndex}
      />
    ) : (
      project.node.frontmatter.coverImageObject.coverImage.childImageSharp && (
        <ProjectImage
          key={project.node.id}
          title={`${project.node.frontmatter.projectTitle} ${project.node.frontmatter.projectSubtitle}`}
          fluid={
            project.node.frontmatter.coverImageObject.coverImage.childImageSharp
              .fluid
          }
          link={`/${project.node.frontmatter.path}/`}
          speed={project.node.frontmatter.coverImageObject.speed}
          align={project.node.frontmatter.coverImageObject.align}
          size={project.node.frontmatter.coverImageObject.size}
          mgTop={project.node.frontmatter.coverImageObject.mgTop}
          mgRight={project.node.frontmatter.coverImageObject.mgRight}
          isGray={project.node.frontmatter.coverImageObject.isGray}
          zIndex={project.node.frontmatter.coverImageObject.zIndex}
          isScaled={project.node.frontmatter.coverImageObject.isScaled}
          haveHalfMask={
            project.node.frontmatter.coverImageObject.mask.haveHalfMask
          }
          haveMask={project.node.frontmatter.coverImageObject.mask.haveMask}
          maskColor={project.node.frontmatter.coverImageObject.mask.maskColor}
        />
      )
    )
  })

  return <section>{projectCover}</section>
}

export default ProjectsSectionTemplate
