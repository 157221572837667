import React, { useEffect } from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import { animated, useSpring } from "react-spring"
import useMousePosition from "@react-hook/mouse-position"
import { useScrollYPosition } from "react-use-scroll-position"
import { useInView } from "react-intersection-observer"

const ImageWrapper = styled.article`
  border: none;
  height: auto;
  width: 100%;
  display: flex;
  margin-top: ${({ mgTop }) => (mgTop ? `${mgTop}%` : `-2%`)};
  justify-content: ${({ align }) =>
    (align === "lewa strona" && "flex-start") ||
    (align === "prawa strona" && "flex-end") ||
    (align === "środek" && "center")};
  @media (max-width: 879px) {
    justify-content: center;
    margin-top: 40px;
  }
`

const CoverImage = styled.figure`
  overflow: hidden;
  margin-right: ${({ mgRight }) => `${mgRight}%`};
  z-index: auto;
  position: relative;
  width: 100%;
  max-width: ${({ size }) => `${size}%`};
  @media (max-width: 879px) {
    max-width: 100%;
    margin-right: 0;
  }
`

const ImageHalfMask = styled.div`
  position: absolute;
  top: -200px;
  left: 0;
  right: 50%;
  bottom: 20%;
  transition: background 0.5s ease-in-out;
  ${CoverImage}:hover & {
    background: ${({ color }) => color};
  }
  @media (max-width: 879px) {
    top: 0;
    background: ${({ color }) => color};
  }
`

const ImageMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: background 0.5s ease-in-out;
  ${CoverImage}:hover & {
    background: ${({ color }) => color};
  }
  @media (max-width: 879px) {
    background: ${({ color }) => color};
  }
`

const ProjectCoverImage = styled(Image)`
  transition: all 0.5s ease-in-out;
  filter: ${({ isGray }) =>
    isGray ? "grayscale(100%) contrast(125%)" : "none"};
  ${CoverImage}:hover & {
    transform: ${({ isScaled }) => (isScaled ? "scale(1.01)" : "none")};
    filter: ${({ isGray }) => (isGray ? "grayscale(0%)" : "none")};
  }
  @media (max-width: 879px) {
    background: ${({ color }) => color};
    filter: ${({ isGray }) => (isGray ? "grayscale(0%)" : "none")};
  }
`

const ProjektName = styled.h1`
  pointer-events: none;
  position: absolute;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  z-index: 100;
  color: #fff;
  font-size: 1.4vw;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 5px;
  overflow: hidden;
  background-color: transparent;
  mix-blend-mode: difference;
`

const ProjectImage = ({
  title,
  fluid,
  link,
  haveMask,
  haveHalfMask,
  maskColor,
  isScaled,
  isGray,
  speed,
  align,
  size,
  mgTop,
  mgRight,
  zIndex,
}) => {
  const scrollY = useScrollYPosition()

  const [mousePositionRef, mousePosition] = useMousePosition(
    20, // enterDelay
    20, // leaveDelay
    30 // fps
  )

  const x = mousePosition.pageX
  const y = mousePosition.pageY
  const isOver = mousePosition.isOver

  const [ref, inView] = useInView({
    rootMargin: "500px 0px",
  })

  const { sY } = useSpring({
    config: {
      mass: 3,
      tension: 220,
      friction: 500,
      clamp: true,
    },
    sY: inView && window.innerWidth > 879 ? scrollY : "none",
  })

  const { o } = useSpring({
    o: inView ? 1 : 0,
  })

  return (
    <>
      <ImageWrapper align={align} mgTop={mgTop}>
        <ParallaxImage
          ref={mousePositionRef}
          mgRight={mgRight}
          size={size}
          style={{
            zIndex: zIndex,
            transform: sY.interpolate(
              (sY) => `translate3d(0, ${sY * speed}px, 0)`
            ),
            opacity: o.interpolate((o) => o),
          }}
        >
          <Link to={link} ref={ref} aria-label={title}>
            {haveMask ? <ImageMask color={maskColor} /> : null}
            {haveHalfMask ? <ImageHalfMask color={maskColor} /> : null}
            <ProjectCoverImage
              fadeIn={false}
              fluid={fluid}
              backgroundColor="#033060a3"
              isScaled={isScaled}
              isGray={isGray}
              alt={title}
            />
          </Link>
        </ParallaxImage>
        <ProjektName
          style={{
            top: isOver ? `${y + 5}px` : "none",
            left: isOver ? `${x + 20}px` : "none",
            opacity: isOver ? "1" : "0",
          }}
        >
          {title}
        </ProjektName>
      </ImageWrapper>
    </>
  )
}

const ParallaxImage = animated(CoverImage)

export default ProjectImage
